import React from 'react';
import { Link } from 'react-router-dom';

const MobileNav = ({ setIsNav }) => {
  return (
    <ul className=' flex flex-col space-y-2 text-white text-[1.3rem] p-8 z-50 '>
      <li>
        <Link
          onClick={() => setIsNav(false)}
          className={` hover:text-[#ff981c] transition-colors duration-200`}
          to={'/'}
        >
          Acceuil
        </Link>
      </li>
      <li>
        <Link
          onClick={() => setIsNav(false)}
          className={` hover:text-[#ff981c] transition-colors duration-200`}
          to={'/etudiants'}
        >
          Etudiants
        </Link>
      </li>
      <li>
        <Link
          onClick={() => setIsNav(false)}
          className={`  hover:text-[#ff981c] transition-colors duration-200`}
          to={'/tourisme'}
        >
          Tourisme
        </Link>
      </li>

      <li>
        <Link
          onClick={() => setIsNav(false)}
          className={`  hover:text-[#ff981c] transition-colors duration-200`}
          to={'/installation'}
        >
          Installation
        </Link>
      </li>
      <li>
        <Link
          onClick={() => setIsNav(false)}
          className={`  hover:text-[#ff981c] transition-colors duration-200`}
          to={'/contact'}
        >
          Contact
        </Link>
      </li>
    </ul>
  );
};

export default MobileNav;
