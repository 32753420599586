import Acceuil from '../pages/Acceuil';
import Contact from '../pages/Contact';
import Etudiants from '../pages/Etudiants';
import Installation from '../pages/Installation';
import Tourisme from '../pages/Tourisme';

export function usePagesRoutes() {
  const pageRoutes = [
    { path: '/', exact: true, component: Acceuil },
    { path: '/contact', component: Contact },
    { path: '/etudiants', component: Etudiants },
    { path: '/tourisme', component: Tourisme },
    { path: '/installation', component: Installation },
  ];
  return {
    pageRoutes,
  };
}
