import React from 'react';
import image from './../../public/images/head_1.jpg';
import flag from './../../public/images/flag.png';

export const HeaderSection = () => {
  return (
    <section>
      <div className='md:h-[40rem] sm:h-[24rem] h-[18rem]  relative'>
        <div className=' absolute bg-[#00000076]   md:hidden  z-20 left-0 bottom-0 right-0 top-0'></div>
        <div className=' h-full z-10 w-full absolute top-0 bottom-0 right-0 left-0'>
          <img
            src={image}
            alt='images'
            className='object-bottom object-cover h-full w-full  md:-translate-x-44 lg:-translate-x-56'
          />
        </div>
        <div className=' w-full h-full  relative  z-30 flex justify-end  items-end'>
          <div className='w-[100%] px-6 md:px-0  md:w-[60%] 2xl:w-[50%] mb-16 md:mb-5  z-50  h-full flex items-center  '>
            <div className=' w-[100%] flex flex-col relative md:ml-[16%]'>
              <h1 className=' text-[1.5rem] w-[30%] sm:w-[23%] md:w-[65%] xl:w-[65%]  text-white uppercase md:text-[3rem] lg:text-[4rem] xl:text-[6rem] font-bold flex flex-col leading-[1] title'>
                <span className=' flex items-end justify-end -mr-[2.5rem] sm:mr-0 '>
                  Esprit
                </span>
                <span> d'accueil</span>
              </h1>
              <span className=' text-[2rem] md:text-[3rem] xl:text-[6rem] md:-mt-5  text-[#ff981c] subtitle font-semibold'>
                Consulting
              </span>
              <div className='flex text-white flex-wrap text-[1rem] xl:text-[1.1rem] absolute xl:w-[100%] 2xl:w-[100%] left-0 md:-left-24 xl:-left-10 -bottom-[8rem]  sm:-bottom-[12rem] lg:-bottom-40'>
                <p className=' hidden sm:block flex-wrap'>
                  Esprit d’accueil consulting est un cabinet de conseil
                  spécialisé dans l'accompagnement et l'intégration complète des
                  étudiants souhaitant étudier en France et des particuliers
                  souhaitant faire du tourisme et/ou s’installer en France. Le
                  but est de simplifier le processus de transition et de rendre
                  l'installation, les études universitaires et/ou le tourisme en
                  France aussi simple qu’elles soient avec nos agences
                  partenaires des différents pays.
                </p>
                <p className=' sm:hidden flex-wrap'>
                  Esprit d’accueil consulting facilite l'intégration d'étudiants
                  et particuliers en France pour études, tourisme, ou
                  installation.
                </p>
              </div>
              <div className='absolute right-0 2xl:right-24 bottom-0'>
                <img
                  className='xl:h-[180px] h-[100px] md:h-[150px] xl:w-[180px] w-[100px] md:w-[150px] object-cover'
                  src={flag}
                  alt='flag'
                />
              </div>
            </div>
          </div>
          <div className=' hidden md:block w-[75%] xl:w-[65%] 2xl:w-[58%] top-0  z-10 right-0 bottom-0   h-full bg-[#177e7e] absolute wave'></div>
        </div>
      </div>
    </section>
  );
};
