import React from 'react';
import Accompagnement from '../components/Etudiants/Accompagnement';
import Job from '../components/Etudiants/Job';

const Etudiants = () => {
  return (
    <main>
      <Accompagnement />
      <Job />
    </main>
  );
};

export default Etudiants;
