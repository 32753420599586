import flag from './../../public/images/singleflag.png';
import etudiant from './../../public/images/etudiant.png';
import amengement from './../../public/images/amengement.png';
import tourisme from './../../public/images/tourisme.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const services = [
  {
    title: 'ACCUEIL DES ETUDIANTS INTERNATIONAUX',
    description: `L’arrivée d’un étudiant à l’aéroport marque le début d'une
              nouvelle aventure ! Notre cabinet spécialisé dans l'accueil des
              étudiants internationaux en France, prend en charge votre candidat
              (étudiant) dès la descente de l'avion, il se fait guider à travers
              les formalités et se conduit jusqu’au logement préparé avec soin.
              Mais notre engagement ne s'arrête pas là : nous les accompagnons
              également dans la transition vers la vie universitaire, en offrant
              un soutien personnalisé et une aide indéniable pour s’installer
              confortablement et se familiariser avec sa nouvelle faculté. Avec
              notre expertise et notre dévouement, vos candidats peuvent aborder
              cette étape importante de leur vie avec confiance et sérénité,
              prêt à embrasser toutes les opportunités que réserve leur parcours
              universitaire en France`,
    image: etudiant,
  },
  {
    title: 'FAIRE DU TOURISME EN FRANCE',
    description: `Nous offrons une gamme de services et d'assistance pour aider les personnes et les familles à s'installer dans le pays. Voici quelques exemples des services qu'une telle entreprise peut proposer`,
    image: tourisme,
    url: '/tourisme',
  },
  {
    title: 'S’INSTALLER EN FRANCE',
    description: `En tant que cabinet de conseil, nous avons négocié des offres de prix spéciales avec nos agences de voyages partenaires en France. Voici quelques détails sur nos offres de prix attractives pour le tourisme en France`,
    image: amengement,
    url: '/installation',
  },
];

const OurService = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [currentDetail, setCurrentDetail] = useState('');

  const handleShowDetails = (description) => {
    setCurrentDetail(description);
    setShowDetail(true);
  };

  const handleCloseDetails = () => {
    setShowDetail(false);
    setCurrentDetail('');
  };

  const navigate = useNavigate();

  return (
    <section className='relative min-h-[600px]'>
      {showDetail && (
        <div
          onClick={handleCloseDetails}
          className='flex items-center justify-center top-0 z-[99999] bg-[#0000005b] fixed h-[100vh] w-[100vw] transition-opacity duration-300'
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className='bg-[#f1f1f1] p-4 rounded-md w-[95%]  overflow-scroll md:w-[40%] h-[40%] transform transition-transform duration-300'
          >
            <p className='text-black'>{currentDetail}</p>
          </div>
        </div>
      )}

      <div className='bg-[#177e7e] border-t-4 border-white -z-10 h-[40%] md:h-[35%] xl:h-[40%] w-full absolute top-0 right-0 left-0'></div>
      <div className='w-[95%] xl:w-[80%] mx-auto'>
        <div className='relative mb-6'>
          <span className='ml-24 -mt-5 text-[2rem] md:text-[3rem] text-[#ff981c] subtitle font-semibold'>
            On vous Offre
          </span>
          <h1 className='text-white uppercase text-[2rem] md:text-[3rem] font-bold flex flex-col leading-[1] title'>
            Nos services
          </h1>
          <div className='z-40 absolute h-[100px] w-[100px] md:h-[200px] md:w-[200px] -left-8 md:-left-28 -top-[2rem] md:top-10'>
            <img
              src={flag}
              alt='images'
              className='h-full w-full object-cover'
            />
          </div>
        </div>

        <div className='grid ourservice_grid'>
          {services.map((service, index) => (
            <div key={index} className='relative'>
              <div className='items-center sm:items-start justify-center flex flex-col relative'>
                <div className='relative h-[350px] w-[300px] md:h-[400px] md:w-[350px]'>
                  <img
                    src={service.image}
                    alt='etudiants'
                    className='h-full w-full object-cover'
                  />
                </div>

                <div
                  className={`w-[70%] sm:w-[50%] md:w-[70%] lg:w-[60%] xl:w-[65%] uppercase text-[#ffff] font-medium absolute bottom-2 bg-[#ff981c] left-0 sm:-left-5 right-5 p-2`}
                >
                  <h1
                    className={`text-[0.8rem] md:text-[1.2rem] sm:text-[1rem]`}
                  >
                    {service.title}
                  </h1>
                </div>
              </div>

              <p className={` ${index === 0 && 'text-white md:text-black'}`}>
                {service.description.length > 200
                  ? service.description.substring(0, 200) + '...'
                  : service.description}
              </p>
              <div className='flex items-end justify-end'>
                <button
                  onClick={() => {
                    if (service.url) {
                      return navigate(service.url);
                    }
                    handleShowDetails(service.description);
                  }}
                  className='text-[0.8rem] md:text-[1rem] mt-2 rounded-full bg-[#ff981c] px-4 py-2 text-white'
                >
                  Savoir plus
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurService;
