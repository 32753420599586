import React from 'react';
import image from './../public/images/installation.png';
const Installation = () => {
  return (
    <main>
      <div className=' grid grid-cols-1 lg:grid-cols-2'>
        <div className=' py-4'>
          <div className=' pb-16 leading-[1]   px-4'>
            <h4 className='     text-[2rem]  lg:text-[2.5rem]  2xl:text-[3.5rem] text-[#ff981c] subtitle font-semibold'>
              On facilite l'installation
            </h4>
            <h1 className='text-[#177e7e] mb-2 uppercase text-[1.5rem] xl:text-[1.5rem] 2xl:text-[3rem] font-bold flex flex-col leading-[1] title'>
              Esprit d accueil facilite l'installation en France
            </h1>
            <p className=' text-[1.2rem]'>
              Nous offrons une gamme de services et d'assistance pour aider les
              personnes et les familles à s'installer dans le pays. Voici
              quelques exemples des services qu'une telle entreprise peut
              proposer :
            </p>
            <ul className=' list-items flex space-y-3 flex-col list-decimal mt-4 ml-4'>
              <li>
                <span>
                  Recherche de logement : esprit d’accueil aider les nouveaux
                  arrivants à trouver un logement adapté à leurs besoins et à
                  leur budget. Cela peut inclure la recherche d'appartements, de
                  maisons ou de logements temporaires tels que des résidences
                  étudiantes ou des logements meublés.
                </span>
              </li>
              <li>
                <span>
                  Assistance administrative : S'installer dans un nouveau pays
                  implique souvent de nombreuses démarches administratives.
                  esprit d’accueil peut aider les personnes à naviguer dans les
                  formalités administratives, telles que l'obtention d'un numéro
                  de sécurité sociale, l'ouverture d'un compte bancaire, la
                  demande de permis de séjour, etc.
                </span>
              </li>
              <li>
                <span>
                  Services de relocation : Pour faciliter l'intégration et
                  l'adaptation à la vie en France, esprit d’accueil peut offrir
                  des services tels que l'orientation dans la ville, des
                  informations sur les écoles et les services de garde
                  d'enfants, des conseils sur les transports publics, etc.
                </span>
              </li>
              <li>
                <span>
                  Assistance linguistique : Si la langue française n'est pas
                  maîtrisée, esprit d’accueil en collaboration avec des
                  associations propose des cours de français ou des services de
                  traduction pour aider les nouveaux arrivants à communiquer et
                  à s'intégrer plus facilement,
                </span>
              </li>
              <li>
                <span>
                  Conseils fiscaux et juridiques : esprit d’accueil peut fournir
                  des conseils sur les questions fiscales et juridiques
                  spécifiques à la France, notamment en ce qui concerne les
                  impôts, les réglementations du travail, les contrats de
                  location, etc.
                </span>
              </li>
              <li>
                <span>
                  Services d'orientation professionnelle : Pour les personnes
                  qui cherchent un emploi en France, esprit d’accueil peut
                  fournir des services d'orientation professionnelle, tels que
                  la rédaction de CV, la préparation d'entretiens d'embauche, la
                  recherche d'opportunités d'emploi, etc.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className='bg-[#177e7e] installion'>
          <img
            src={image}
            alt='image'
            className=' object-top h-[600px] lg:h-full w-full object-cover'
          />
        </div>
      </div>
    </main>
  );
};

export default Installation;
