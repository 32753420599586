import img_1 from './../public/images/etudiant_6.png';
import img_2 from './../public/images/etudiant_7.png';
const Tourisme = () => {
  return (
    <main className=' w-full'>
      <div className=' grid grid-cols-1 lg:grid-cols-2 w-full'>
        <div className=' h-full px-4'>
          <h4 className='  rotate  text-[2.5rem] lg:text-[3rem]  xl:text-[4rem] text-[#ff981c] subtitle font-semibold'>
            Nous vous accompagnons
          </h4>
          <h1 className='text-[#177e7e] uppercase text-[1.5rem] lg:text-[3rem] font-bold flex flex-col leading-[1] title'>
            tout au long de votre parcours touristique.
          </h1>
          <p className=' text-[1.2rem]'>
            En tant que cabinet de conseil, nous avons négocié des offres de
            prix spéciales avec nos agences de voyages partenaires en France.
            Voici quelques détails sur nos offres de prix attractives pour le
            tourisme en France :
          </p>
          <ul className=' list-items flex space-y-3 flex-col list-decimal mt-4 ml-4'>
            <li>
              <span>
                Forfaits tout compris : Nous proposons des forfaits tout compris
                qui incluent le transport, l'hébergement et les activités
                touristiques. Vous n'avez donc pas à vous soucier de
                l'organisation, tout est prévu pour vous permettre de profiter
                pleinement de votre séjour.
              </span>
            </li>
            <li>
              <span>
                Réductions et promotions : Nous offrons régulièrement des
                réductions et des promotions sur nos séjours en France. Cela
                vous permet de bénéficier de tarifs avantageux et de faire des
                économies sur vos vacances.
              </span>
            </li>
            <li>
              <span>
                Itinéraires personnalisés : Nous travaillons en étroite
                collaboration avec nos partenaires pour créer des itinéraires
                personnalisés en fonction de vos préférences et de vos intérêts.
                Que vous souhaitiez découvrir les sites historiques, profiter
                des plages, explorer la nature ou déguster la cuisine française,
                nous pouvons vous proposer un programme sur mesure.
              </span>
            </li>
            <li>
              <span>
                Guides locaux : Pour rendre votre expérience encore plus
                enrichissante, nous collaborons avec des guides locaux
                expérimentés. Ils vous feront découvrir les secrets et les
                trésors cachés de chaque destination, vous permettant ainsi de
                vivre une expérience authentique.
              </span>
            </li>
            <li>
              <span>
                Assistance 24h/24 et 7j/7 : Notre équipe est disponible pour
                vous fournir une assistance en cas de besoin pendant votre
                séjour. Que ce soit pour des questions, des problèmes ou des
                demandes spéciales, nous sommes là pour vous aider.
              </span>
            </li>
          </ul>
          <p>
            {' '}
            N'hésitez pas à nous contacter pour plus d'informations ou pour
            réserver votre prochaine escapade touristique en France.
          </p>
        </div>
        <div className=' relative min-h-[100vh] md:min-h-screen'>
          <div>
            <div>
              <div className=' absolute top-5'>
                <img
                  src={img_1}
                  alt='image'
                  className=' border-[#177e7e] border-[10px] h-[300px] w-[300px] sm:h-[500px] sm:w-[500px] object-cover rounded-full'
                />
              </div>
            </div>
            <div className=' absolute top-60 right-4 xl:right-[10rem]'>
              <div>
                <img
                  src={img_2}
                  alt='image'
                  className=' border-[#177e7e] border-[10px] h-[300px] w-[300px] sm:h-[500px] sm:w-[500px] object-cover rounded-full'
                />
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Tourisme;
