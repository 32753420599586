import decor from './../../public/images/decor_2.png';
import megaphpne from './../../public/svg/megaphone.svg';

const Logement = () => {
  return (
    <section className=' mt-4 relative min-h-[800px] md:min-h-[650px] lg:min-h-[600px]'>
      <div className=' z-0 absolute logement__bg  bg-[#177e7e] top-0 right-0 left-0 bottom-0  h-[100%] w-full '></div>
      <div className=' grid-rows-2 lg:grid-rows-1  grid-cols-1  grid lg:grid-cols-2 z-40 relative pt-[25rem] md:pt-80 lg:pt-56'>
        <div className='   h-[500px] lg:h-[600px] sm:-mt-[6rem]  lg:mt-0 '>
          <img
            src={decor}
            alt='image'
            className=' h-full w-full object-cover'
          />
        </div>
        <div className=' relative basis-[100%]  md:basis-[50%]  '>
          <div className='-mt-[46rem] sm:-mt-[38rem] md:-mt-[40rem] lg:-mt-72  xl:-mt-44  2xl:mt-0 px-4'>
            <h4 className='  rotate  text-[2.5rem] lg:text-[3rem]  xl:text-[4rem] text-[#ff981c] subtitle font-semibold'>
              On s'occupe de la
            </h4>
            <h1 className='text-white lg:text-[#177e7e] uppercase text-[1.5rem] lg:text-[3rem] font-bold flex flex-col leading-[1] title'>
              RECHERCHE DU LOGEMENT
            </h1>

            <p className=' mt-8 text-[1.2rem]'>
              Dès le dépôt de la demande de visa de votre candidat, vous pouvez
              nous contacter pour lancer la recherche du logement et préparer
              son accueil.
            </p>
          </div>

          <div className=' flex-col lg:flex-row md:space-x-4 flex p-3 text-white bg-[#ff981c] absolute bottom-0 right-0'>
            <img
              src={megaphpne}
              alt='megaphpne'
              className=' h-[100px] w-[100px]'
            />
            <p className=' flex-1 text-[1.1rem]'>
              "Dédiée à l'expérience étudiante, notre cabinet excelle dans la
              recherche d'appartements offrant confort et praticité. Nous
              sélectionnons méticuleusement des logements bien situés à côté des
              universités, sécurisés et adaptés aux besoins spécifiques des
              étudiants ainsi qu’à leur budget. Grâce à notre expertise et notre
              réseau étendu d’agence immobilier, nous allons trouver un
              logement, offrant à l’étudiant une tranquillité d'esprit et un
              environnement propice à sa réussite académique."
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Logement;
