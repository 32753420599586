import React from 'react';

const Footer = () => {
  return (
    <footer className=' bg-[#177e7e] py-6 '>
      <div className='h-[2rem] flex items-center justify-center'>
        <p className='text-white text-sm'>
          © 2024 Esprit d'accueil. Tous Droits Reservés.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
