import job from './../../public/images/job_4.png';
import megaphpne from './../../public/svg/megaphone.svg';

const Job = () => {
  return (
    <section className=' relative pt-36'>
      <div className=' job bg-[#177e7e] absolute top-0 bottom-0 right-0 left-0 z-0'></div>
      <div className=' mb-[38rem] sm:mb-[25rem] lg:mb-[16rem] xl:mb-[16rem] 2xl:mb-2 relative z-50 w-[95%] md:w-[90%] mx-auto '>
        <div className=' mt-[18rem] sm:mt-60 2xl:mt-3  md:mt-36 h-[500px]  w-full  2xl:h-[600px] 2xl:w-[1200px]'>
          <img src={job} alt='Emploi' className=' h-full w-full object-cover' />
        </div>
        <div className=' pb-16 leading-[1]   px-4'>
          <h4 className='     text-[2rem]  lg:text-[2.5rem]  2xl:text-[3.5rem] text-[#ff981c] subtitle font-semibold'>
            On Vous trouve un petit
          </h4>
          <h1 className='text-[#177e7e] uppercase text-[1.5rem] xl:text-[1.5rem] 2xl:text-[3rem] font-bold flex flex-col leading-[1] title'>
            JOB ETUDIANT
          </h1>
        </div>
        <div className=' -top-[21rem] sm:-top-[18rem] md:-top-[22rem] lg:-top-[15rem]  xl:-top-[10rem] 2xl:-top-6  w-[100%] md:w-[80%]  xl:w-[80%] 2xl:w-[40%]  right-0 absolute  z-50'>
          <div className=' text-white flex flex-col p-10  bg-[#ff981c]  w-full '>
            <div className=' w-full justify-center flex'>
              <h1 className=' uppercase text-[1.5rem] font-semibold'>
                Avantage du job etudiant
              </h1>
            </div>

            <p className=' text-[1.1rem]'>
              Travailler pendant ses études permet aux étudiants de gagner de
              l'argent pour subvenir à leurs besoins, comme le logement, la
              nourriture et les frais de scolarité. Cela peut également les
              aider à réduire leur dépendance vis-à-vis de leurs parents ou de
              prêts étudiants.
            </p>
          </div>
        </div>
        <div className=' -bottom-[35rem] sm:-bottom-[20rem] md:-bottom-[25rem] lg:-bottom-[15rem] xl:-bottom-[15rem] lg:w-[100%] w-[100%]  absolute right-0 2xl:bottom-[8rem]     flex-col 2xl:flex-row flex 2xl:items-center 2xl:w-[60%]'>
          <div className=' p-4 w-[150px] h-[150px] bg-[#ff981c]'>
            <img
              src={megaphpne}
              alt='megaphpne'
              className=' h-[100px] w-[100px]'
            />
          </div>
          <div className='  p-2 text-white flex-1 bg-[#177e7e] '>
            <p className=' flex-1 text-[1.1rem]'>
              "Notre cabinet offre un accompagnement personnalisé aux étudiants
              après leurs études pour les aider dans leur transition vers le
              marché du travail. Nous proposons des conseils professionnels, des
              ateliers de développement de carrière et des opportunités de
              réseautage pour les aider à démarrer leur carrière du bon pied.
              Avec notre expertise et notre engagement à soutenir chaque
              étudiant, nous les aidons à naviguer avec confiance dans le monde
              professionnel et à réaliser leur plein potentiel."
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Job;
