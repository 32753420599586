import flag from './../../public/images/singleflag.png';

const OnSoccupe = () => {
  return (
    <section className=' w-[95%] mt-4 sm:mt-0 mx-auto relative'>
      <div>
        <h4 className='  rotate  text-[2.5rem]  md:text-[4rem] text-[#ff981c] subtitle font-semibold'>
          On s'occupe de l'
        </h4>
        <h1 className='text-[#177e7e] uppercase text-[1.5rem] sm:text-[2rem] lg:text-[3rem] font-bold flex flex-col leading-[1] title'>
          ACCUEIL DES ETUDIANTS <br /> INTERNATIONAUX
        </h1>
      </div>{' '}
      <div className=' z-40 absolute h-[100px] w-[100px] lg:h-[200px] lg:w-[200px] left-[12rem] lg:left-[35rem] -top-2 lg:top-10'>
        <img src={flag} alt='images' className=' h-full w-full object-cover' />
      </div>
    </section>
  );
};

export default OnSoccupe;
