import ContactUs from '../components/Contact/ContactUs';
import OurTeams from '../components/Contact/OurTeams';

const Contact = () => {
  return (
    <main>
      <OurTeams />
      <ContactUs />
    </main>
  );
};

export default Contact;
