import ige_1 from './../../public/images/contact_2.png';
import logo from './../../public/images/logo.png';

const ContactUs = () => {
  return (
    <section className=' translate-y-2 relative  pb-16'>
      <div className='bg-[#177e7e]  z-[10]  contact h-full w-full absolute'></div>
      <div className=' relative z-40 flex flex-wrap  flex-col xl:flex-row'>
        <div className=' mb-80 xl:mb-0 basis-[100%] xl:basis-[50%]'>
          <img
            src={ige_1}
            className=' h-[550px] w-full  object-cover'
            alt='Etudiant'
          />
        </div>
        <div className='  basis-[100%] xl:basis-[50%] relative  items-start flex-col xl:flex-row    flex justify-end'>
          <div className=' -translate-y-[18rem]  xl:translate-y-0 flex items-center'>
            <div className='relative z-[60]'>
              <h4 className='     text-[2rem]  sm:text-[2.5rem] 2xl:text-[4rem] text-[#ff981c] subtitle font-semibold'>
                Pour plus d'
              </h4>
              <h1 className='text-[#177e7e] uppercase text-[1.5rem] sm:text-[2rem] lg:text-[3rem] font-bold flex flex-col leading-[1] title'>
                Information
              </h1>
            </div>
            <div>
              <img
                src={logo}
                alt='logo'
                className=' h-[100px] w-[100px]  md:h-[150px] object-cover md:w-[150px]'
              />
            </div>
          </div>
          <div className='bg-[#ff981c] p-2 md:p-8 absolute left-0 bottom-0'>
            <div>
              <ul className=' gap-3 grid grid-cols-1 md:grid-cols-2'>
                <li className=' space-x-3 flex items-center'>
                  <span className=' rounded-full inline-block p-3 bg-white'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='size-6 text-[#ff981c]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z'
                      />
                    </svg>
                  </span>
                  <div className=' text-white text-[0.9rem]  md:text-[1.1rem] flex flex-col'>
                    <span>+33 (0) 1 79 75 81 11</span>
                    <span>+33 (0) 7 66 76 35 17</span>
                  </div>
                </li>

                <li className=' space-x-3 flex items-center'>
                  <span className=' rounded-full inline-block p-3 bg-white'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='size-6 text-[#ff981c]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75'
                      />
                    </svg>
                  </span>
                  <div className=' text-white text-[0.9rem]  md:text-[1.1rem] flex flex-col'>
                    <a
                      href='mailto:contact@espritdaccueil.com'
                      class='email-link'
                    >
                      contact@espritdaccueil.com
                    </a>
                  </div>
                </li>
                <li className=' space-x-3 flex items-start'>
                  <span className=' rounded-full inline-block p-3 bg-white'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='size-6 text-[#ff981c]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z'
                      />
                    </svg>
                  </span>
                  <div className=' text-white text-[0.9rem]  md:text-[1.1rem] flex flex-col'>
                    <span>90 Boulevard Anatole France</span>
                    <span>93200 Saint-Dénis, </span>
                    <span>SEINE SAINT DENIS </span>
                    <span>ILE-DE-FRANCE</span>
                    <span>FRNACE</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
