import ige_1 from './../../public/images/travel_1.png';
import ige_2 from './../../public/images/paris_5.png';
import ige_3 from './../../public/images/travel_3.png';
import ige_4 from './../../public/images/travel.png';
import megaphpne from './../../public/svg/megaphone.svg';

const gallery = [ige_1, ige_3, ige_4, ige_2];

const Reception = () => {
  return (
    <section className=' relative min-h-[700px] sm:mt-10 lg:pt-10  '>
      <div className='bg-[#177e7e]  z-[50]  reception h-full w-full absolute'></div>
      <div className=' relative grid-rows-2 lg:grid-rows-1 grid-cols-1 lg:grid-cols-2  grid w-full md:w-[95%] 2xl:w-[80%] mx-auto'>
        <div className=' relative z-[60] '>
          <div className='gallery  grid relative'>
            {gallery.map((gallery, index) => (
              <div
                className={
                  index == 3
                    ? ` lg:h-[350px] lg:w-[300px] h-[200px] w-[200px] md:h-[250px] md:w-[250px]  border-[16px] border-white absolute bottom-10 right-[1rem] -rotate-6 `
                    : index == 2
                    ? `lg:h-[350px] lg:w-[300px] h-[200px] w-[200px] md:h-[250px] md:w-[250px] border-[16px] border-white  absolute right-[9rem]  bottom-24 sm:right-24 lg:right-72  `
                    : index == 0
                    ? `lg:h-[350px] lg:w-[300px] h-[200px] w-[200px] md:h-[250px] md:w-[250px] border-[16px] border-white  absolute bottom-52  sm:bottom-[18rem]  2xl:bottom-40 right-[5rem] sm:right-[18rem] md:right-[20rem]  2xl:right-[30rem] `
                    : `lg:h-[350px] lg:w-[300px] h-[200px] w-[200px] md:h-[250px] md:w-[250px] relative -right-32 sm:-right-56 -bottom-24 border-[16px] border-white   `
                }
                key={index}
              >
                <img
                  src={gallery}
                  alt=''
                  className=' object-cover h-full w-full'
                />
              </div>
            ))}
          </div>
        </div>
        <div className='basis-[50%] relative'>
          <div className='relative z-[60] lg:-mt-[5rem] 2xl:-mt-8  sm:mt-0'>
            <h4 className='  rotate  text-[2rem]  sm:text-[2.5rem] 2xl:text-[4rem] text-[#ff981c] subtitle font-semibold'>
              On s'occupe de la
            </h4>
            <h1 className=' text-white lg:text-[#177e7e] uppercase text-[1.5rem] lg:text-[2rem] 2xl:text-[3rem]  font-bold flex flex-col leading-[1] title'>
              RECEPTION a L’AEROPORT <br /> JUSQU’AU LOGEMENT
            </h1>
          </div>

          <div className='  relative z-[60] text-[0.9rem] sm:text-[1.1rem] sm:mt-4 md:ml-2 xl:ml-4 sm:ml-10'>
            <p>
              Après son obtention du visas, et après avoir preparer son
              logement, nous allons ensemble organiser le voyage de votre
              candidat ainsi que son accueil depuis l’aeroport de Paris jusqu’à
              son logement. On s’en charge à lui offrir une petite session de
              tourisme et découverte dans sa nouvelle ville.
            </p>
          </div>
          <div className='  md:space-x-4 flex-col xl:flex-row flex p-3 text-white  absolute bottom-0 right-0 lg:-right-[2rem] 2xl:-right-[10rem] sm:-left-8'>
            <img
              src={megaphpne}
              alt='megaphpne'
              className=' relative z-[60] h-[100px] w-[100px]'
            />
            <p className=' relative z-[60] flex-1 text-[0.9rem] sm:text-[1.1rem]'>
              "Dès son arrivée à l'aéroport, notre cabinet offre un accueil
              personnalisé aux étudiants internationaux en France. Nous assurons
              un transfert sans tracas vers le logement étudiant, les mettant à
              l'aise dès le départ. Notre équipe dévouée est là pour répondre à
              tous les besoins et lui fournir des informations précieuses sur
              son nouvel environnement. Avec notre service attentionné,
              l’étudiant se sent soutenu dès le début de son aventure
              éstudiantine en France."
            </p>
            <div className='bg-[#ff981c] top-0 bottom-0 right-0 left-0 absolute -z-[100]'></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reception;
