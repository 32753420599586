import ige_1 from './../../public/images/etdudiant_4.png';
import ige_2 from './../../public/images/etudiant_1.png';

import megaphpne from './../../public/svg/megaphone.svg';

const gallery = [ige_1, ige_2];

const Integration = () => {
  return (
    <section className=' translate-y-1 relative min-h-[800px] mt-10  '>
      <div className='bg-[#177e7e]  z-[50]  integration h-full w-full absolute'></div>
      <div className=' relative lg:space-x-10 md:pt-20 flex-col  lg:flex-row flex w-[100%] mx-auto'>
        <div className=' relative z-[60] basis-[100%] lg:basis-[40%]'>
          <div className='flex flex-col relative'>
            {gallery.map((gallery, index) => (
              <div
                className={
                  index === 0
                    ? `md:h-[450px]  relative  w-[100%]     `
                    : `md:h-[400px] relative flex items-end justify-end pl-4 md:pl-0 md:translate-x-10  -translate-y-10    w-[100%] `
                }
                key={index}
              >
                <img
                  src={gallery}
                  alt=''
                  className={' object-cover h-full w-full'}
                />
              </div>
            ))}
          </div>
        </div>
        <div className=' basis-[100%] lg:basis-[60%] relative'>
          <div className='relative -mt-7 md:mt-0  z-[60]'>
            <h4 className='  rotate   text-[2rem]  sm:text-[2.5rem] 2xl:text-[4rem] text-[#ff981c] subtitle font-semibold'>
              On s'occupe de la
            </h4>
            <h1 className='lg:text-[#177e7e] bg-[#177e7e] lg:bg-transparent py-4 px-1  text-white  uppercase text-[1.2rem] sm:text-[1.5rem] lg:text-[2rem] 2xl:text-[3rem] font-bold flex flex-col leading-[1] title'>
              INTEGRATION DE l’étudiant ET LES DéMARCHES ADMINISTRATIVES
            </h1>
          </div>

          <div className=' mb-[22rem] sm:mb-[12rem] md:mb-[6rem]  lg:mb-[8rem] xl:mb-[10rem] 2xl:mb-[5rem]  relative z-[60] text-[1.1rem] mt-4 ml-1 md:ml-10'>
            <p className='text-[1rem] md:text-[1.1rem]'>
              Ça y est, votre candidat est maintenant en France ! et ce n’est
              pas finis ! On s’occupe de son intégration et de démarches
              administrative pour ses papiers en France.
            </p>
            <ul className=' mt-3 mb-5'>
              <li className='  flex items-start '>
                <span className=' border-[#ff981c] mr-3 block border-2 text-[#ff981c] p-2 rounded-full'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    class='size-6  text-[#ff981c]'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='m8.25 4.5 7.5 7.5-7.5 7.5'
                    />
                  </svg>
                </span>
                <span className='text-[1rem] md:text-[1.1rem]'>
                  Nous organisons des activités d'intégration pour les étudiants
                  afin de les aider à se connecter avec d'autres étudiants
                  internationaux et locaux. Cela peut inclure des visites de la
                  ville, des événements sociaux, des séances d'orientation sur
                  le campus, des rencontres avec des associations étudiantes,
                  etc. Notre objectif est de favoriser un environnement
                  chaleureux et inclusif pour les étudiants afin qu'ils puissent
                  se sentir à l'aise et s'intégrer rapidement.
                </span>
              </li>
              <li className=' flex items-start mt-3 '>
                <span className=' border-[#ff981c] mr-3 block border-2 text-[#ff981c] p-2 rounded-full'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    class='size-6  text-[#ff981c]'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='m8.25 4.5 7.5 7.5-7.5 7.5'
                    />
                  </svg>
                </span>
                <div>
                  <span className='text-[1rem] md:text-[1.1rem]'>
                    Sur la partie administrative on s’occupe de :
                  </span>
                  <ul className=' text-[1rem] md:text-[1.1rem] list-outside list-disc ml-5 md:ml-12 '>
                    <li>
                      <span>Démarche OFII;</span>
                    </li>
                    <li>
                      <span>Démarche CAF;</span>
                    </li>
                    <span>Assurance santé (mutuelle étudiante);</span>
                    <li>
                      <span>Ouverture d’un compte bancaire;</span>
                    </li>
                    <li>
                      <span>Titre de séjour.</span>
                    </li>
                  </ul>
                </div>
              </li>
              <div className=' text-[1rem] md:text-[1.1rem] ml-3 md:ml-16 mt-3'>
                <p>
                  Ces démarches administratives sont cruciales pour assurer une
                  transition en douceur et une integration réussie en France
                  pour un étudiant étranger débutant sa première année d’études.
                </p>
              </div>
            </ul>
          </div>
          <div className=' min-h-52'>
            <div className='  flex-col md:flex-row md:space-x-4 flex p-3 text-white  absolute bottom-0  -left-[0rem] lg:-left-[20rem] xl:left-0 lg:right-0'>
              <img
                src={megaphpne}
                alt='megaphpne'
                className=' relative z-[60] h-[100px] w-[100px]'
              />
              <p className=' relative z-[60] flex-1 text-[1rem] md:text-[1.1rem]'>
                Notre cabinet se distingue par son expertise approfondie dans
                les démarches administratives et l'intégration des étudiants
                étrangers en France. Avec notre réseau étendu et notre soutien
                personnalisé, nous garantissons une transition en toute fluidité
                pour les étudiants. Notre engagement à offrir un accompagnement
                complet, de l’inscription à l’université jusqu’à l'installation
                pour assurer une expérience sans stress à nos étudiants. En
                choisissant notre entreprise, les étudiants bénéficient d'une
                tranquillité d'esprit et peuvent se concentrer pleinement sur
                leurs études et leur épanouissement personnel en France
              </p>
              <div className='bg-[#ff981c] top-0 bottom-1 right-0 left-0 absolute -z-[100]'></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Integration;
