import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { usePagesRoutes } from './usePagesRoutes';
import Footer from '../utilitises/Footer';
import Header from '../utilitises/Header';

const PagesRouteses = () => {
  const { pageRoutes } = usePagesRoutes();
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {pageRoutes.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default PagesRouteses;
