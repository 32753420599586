import React from 'react';
import madoussou from './../../public/images/madoussou.jpeg';
import jerome from './../../public/images/jerome.jpeg';
import phillipe from './../../public/images/phillipe.jpeg';
import serge from './../../public/images/serge.jpeg';
import majoie from './../../public/images/magloi.jpeg';
import leaticia from './../../public/images/leaticia.jpeg';

const personal = [
  {
    pers: madoussou,
    name: 'MADOUSSOU KONE',
    post: 'Fondatrice et présidente',
  },

  {
    pers: phillipe,
    name: 'PhiLIPPE TSHINIAMA',
    post: 'Accompagnateur',
  },
  {
    pers: serge,
    name: 'SERGE AHOUA',
    post: `Représentant Afrique de l’Ouest`,
  },
  {
    pers: majoie,
    name: 'MAJOIE DIKIOWO',
    post: `Co-fondateur`,
  },
  {
    pers: leaticia,
    name: 'LAETITIA NTOYA',
    post: `Accompagnatrice`,
  },
  {
    pers: jerome,
    name: 'JEROME GONZALES',
    post: 'Accompagnateur',
  },
];

const OurTeams = () => {
  return (
    <section className=' relative'>
      <div className='bg-[#177e7e]  z-[10]  teams h-full w-full absolute'></div>
      <div className=' relative z-40 flex flex-col  xl:flex-row w-[95%]  xl:w-[85%] mx-auto'>
        <div className=' w-[20%]'>
          <h4 className='   text-[2rem]  sm:text-[2.5rem] 2xl:text-[4rem] text-[#ff981c] subtitle font-semibold'>
            Nous
          </h4>
          <h1 className='text-[#fff] uppercase text-[1.2rem] sm:text-[1.5rem] lg:text-[2rem] 2xl:text-[3rem] font-bold flex flex-col leading-[1] title'>
            NOTRE <br /> EQUIPE
          </h1>
        </div>
        <ul className=' mt-16 flex-1 relative personal__grid grid grid-cols-3 '>
          {personal.map((person, index) => (
            <li
              className={
                index === 0
                  ? 'absolute -top-[5rem] right-0 md:top-0 md:right-10'
                  : index === 1
                  ? 'absolute bottom-0 right-0 md:right-10'
                  : index === 2
                  ? 'absolute md:top-0 md:right-[24rem]  top-[12rem] right-[0]'
                  : index === 3
                  ? 'absolute md:bottom-0 right-[6.5rem] md:right-[24rem]'
                  : index === 4
                  ? 'absolute top-[16rem] lg:top-36 right-[10rem]  md:right-[30rem] md:top-[16rem] lg:right-[44rem]'
                  : index === 5
                  ? 'absolute top-[32rem] xl:top-72 md:top-[16rem] md:left-[14rem]  left-[2rem] lg:left-[8rem] lg:-top-[10rem]   xl:-left-[20rem]'
                  : ' absolute'
              }
            >
              <div
                className={
                  ' h-[200px] w-[150px] xl:h-[350px] lg:h-[300px] border-[1px] border-[#36363654]  xl:w-[300px] lg:w-[250px]'
                }
              >
                <img
                  src={person.pers}
                  alt='image'
                  className=' border-8 border-white object-cover h-full w-full'
                />
              </div>
              <div className=' uppercase text-white -translate-x-8 mt-1 bg-[#ff981c] p-4 text-[0.7rem] md:text-[1rem]'>
                <p>{person.name} </p>
              </div>
              <div className='bg-[#177e7e] text-white p-2 text-[0.7rem] md:text-[1rem]'>
                <p>{person.post} </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default OurTeams;
