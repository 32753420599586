import React from 'react';
import { HeaderSection } from '../components/Accueil/HeaderSection';
import OurService from '../components/Accueil/OurService';
import OnSoccupe from '../components/Accueil/OnSoccupe';
import Logement from '../components/Accueil/Logement';
import Reception from '../components/Accueil/Reception';
import Integration from '../components/Accueil/Integration';

const Acceuil = () => {
  return (
    <main>
      <HeaderSection />
      <OurService />
      <OnSoccupe />
      <Logement />
      <Reception />
      <Integration />
    </main>
  );
};

export default Acceuil;
