import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../public/images/logo.png';
import MobileNav from './MobileNav';

const Header = () => {
  const location = useLocation();

  const [isnav, setIsNav] = useState(false);

  return (
    <header className='  relative   z-[99999]  h-full  items-center flex w-full'>
      {isnav && (
        <div
          className={`fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 z-[99999998] transition-opacity duration-300`}
          onClick={() => setIsNav(false)}
        ></div>
      )}
      <div
        onClick={() => setIsNav(false)}
        className={`fixed top-0 bottom-0 w-[60%] z-[99999999] bg-[#177e7e] transform transition-transform md:hidden ${
          isnav ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {isnav && <MobileNav setIsNav={setIsNav} />}
      </div>
      <div className='bg-[#177e7e] top-0 bottom-0 sticky items-center  h-full w-full '>
        <nav className=' h-full   flex items-center justify-between w-[80%] mx-auto'>
          <Link to={'/'}>
            <img
              src={logo}
              className=' h-[70px]  object-cover w-[100px]'
              alt=''
            />
          </Link>

          <ul className='  hidden  md:flex items-center font-[600]   space-x-4 text-white text-[1.3rem] '>
            <li>
              <Link
                className={` ${
                  location.pathname === '/' && 'text-[#ff981c]'
                } hover:text-[#ff981c] transition-colors duration-200`}
                to={'/'}
              >
                Acceuil
              </Link>
            </li>
            <li>
              <Link
                className={` ${
                  location.pathname === '/etudiants' && 'text-[#ff981c]'
                } hover:text-[#ff981c] transition-colors duration-200`}
                to={'/etudiants'}
              >
                Etudiants
              </Link>
            </li>
            <li>
              <Link
                className={` ${
                  location.pathname === '/tourisme' && 'text-[#ff981c]'
                } hover:text-[#ff981c] transition-colors duration-200`}
                to={'/tourisme'}
              >
                Tourisme
              </Link>
            </li>
            <li>
              <Link
                className={` ${
                  location.pathname === '/installation' && 'text-[#ff981c]'
                } hover:text-[#ff981c] transition-colors duration-200`}
                to={'/installation'}
              >
                Installation
              </Link>
            </li>
            <li>
              <Link
                className={` ${
                  location.pathname === '/contact' && 'text-[#ff981c]'
                } hover:text-[#ff981c] transition-colors duration-200`}
                to={'/contact'}
              >
                Contact
              </Link>
            </li>
          </ul>

          <button
            onClick={() => {
              setIsNav(!isnav);
            }}
            className='md:hidden navbtn relative cursor-pointer z-[9999999]'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-8 text-white cursor-pointer'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
              />
            </svg>
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header;
