import gratuate from './../../public/images/graduate_2.png';
import megaphpne from './../../public/svg/megaphone.svg';

const Accompagnement = () => {
  return (
    <section className='  relative min-h-[600px]'>
      <div className=' z-0 absolute accompagnement__bg  bg-[#177e7e] top-0 right-0 left-0 bottom-0  h-[100%] w-full '></div>
      <div className=' flex-col xl:flex-row  flex z-40 relative pt-0 xl:pt-64'>
        <div className='mb-[15rem] sm:mb-[16rem] xl:-mb-2 relative basis-[100%] xl:basis-[50%] h-[600px] '>
          <img
            src={gratuate}
            alt='image'
            className=' h-full w-full object-cover'
          />
        </div>
        <div className=' relative  basis-[100%] xl:basis-[50%]'>
          <div className='  mb-[22rem] sm:mb-[8rem] md:mb-[2rem] xl:mb-[2rem] 2xl:mb-4 leading-[1] sm:-translate-y-[16rem] -translate-y-56  px-4'>
            <h4 className='  ml-10   text-[1.5rem]  lg:text-[2rem]  2xl:text-[3.5rem] text-[#ff981c] subtitle font-semibold'>
              On est toujours là et on vous offres
            </h4>
            <h1 className='text-[#177e7e] ml-10 uppercase text-[1.5rem] xl:text-[1.5rem] 2xl:text-[2.5rem] font-bold flex flex-col leading-[1] title'>
              ACCOMPAGNEMENT POST ETUDE
            </h1>

            <p className=' mt-8 text-[1.2rem]'>
              Dès le dépôt de la demande de visa de votre candidat, vous pouvez
              nous contacter pour lancer la recherche du logement et préparer
              son accueil.
            </p>
          </div>
          <div className=' ml-10 text-white  bg-[#177e7e] p-4 w-[90%] -translate-y-[35rem] sm:-translate-y-[23rem] md:-translate-y-[16rem] lg:-translate-y-[16rem] xl:-translate-y-[16rem] relative z-50'>
            <p>Voici notre offre d’accompagnement après les études : </p>
            <ul className=' ml-6'>
              <li>
                <span>-ateliers de développement de carrière, (CV…),</span>
              </li>
              <li>
                <span>-programmes de mentorat, </span>
              </li>
              <li>
                <span>-accès à des opportunités professionnelles,</span>
              </li>
              <li>
                <span>-événements de réseautage, </span>
              </li>
              <li>
                <span>
                  -changement de statut, demande d’autorisation de travail…
                </span>
              </li>
            </ul>
          </div>

          <div className=' flex-col md:flex-row md:space-x-4 flex pt-20 xl:pt-20 pb-10 px-3 text-white bg-[#ff981c] absolute bottom-0'>
            <img
              src={megaphpne}
              alt='megaphpne'
              className=' h-[100px] w-[100px]'
            />
            <p className=' flex-1 text-[1.1rem]'>
              "Notre cabinet offre un accompagnement personnalisé aux étudiants
              après leurs études pour les aider dans leur transition vers le
              marché du travail. Nous proposons des conseils professionnels, des
              ateliers de développement de carrière et des opportunités de
              réseautage pour les aider à démarrer leur carrière du bon pied.
              Avec notre expertise et notre engagement à soutenir chaque
              étudiant, nous les aidons à naviguer avec confiance dans le monde
              professionnel et à réaliser leur plein potentiel."
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accompagnement;
